import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/global/react-popper-tooltip.css';
import { usePopperTooltip } from 'react-popper-tooltip';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Icon from '../common/icon';
import InputSelect from '../common/inputSelect';

import Constants from '../../constants';

function AdminAppointmentOptions({ appt }) {
  const [showModal, setShowModal] = useState(null);
  const [visible, setVisible] = useState(false);

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } = usePopperTooltip({
    trigger: 'click',
    onVisibleChange: () => setVisible(!visible),
  });

  const toggleModal = modalToDisplay => {
    setVisible(false);
    setShowModal(modalToDisplay);
  };

  const dropdownOptions = [];

  dropdownOptions.push({
    text: 'Generate Consent Form',
    handleClick: () => toggleModal('generate_consent_form'),
  });

  dropdownOptions.push({
    text: 'Generate Appointment Summary',
    handleClick: () => toggleModal('generate_appointment_summary'),
  });

  dropdownOptions.push({
    text: 'Generate Treatment Sheet',
    handleClick: () => toggleModal('generate_treatment_sheet'),
  });

  dropdownOptions.push({
    text: 'Generate Intake Docs',
    handleClick: () => toggleModal('generate_intake_docs'),
  });

  if (dropdownOptions.length === 0) {
    return null;
  }

  return (
    <div ref={setTriggerRef}>
      <Icon name="options_dots" className="me-0" tooltipText="Admin Options" />
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container options' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          <div className="d-flex flex-column">
            {dropdownOptions.map((t, i) => (
              <button key={i} type="button" onClick={t.handleClick}>
                {t.text}
              </button>
            ))}
          </div>
        </div>
      )}
      {showModal === 'generate_consent_form' && (
        <GenerateConsentFormModal appt={appt} handleClose={toggleModal} />
      )}
      {showModal === 'generate_appointment_summary' && (
        <GenerateApptSummaryModal appointmentId={appt.id} handleClose={toggleModal} />
      )}
      {showModal === 'generate_treatment_sheet' && (
        <GenerateTreatmentSheetModal
          petId={appt.pet_id}
          appointmentId={appt.id}
          handleClose={toggleModal}
        />
      )}
      {showModal === 'generate_intake_docs' && (
        <GenerateIntakeDocsModal appointmentId={appt.id} handleClose={toggleModal} />
      )}
    </div>
  );
}

export default AdminAppointmentOptions;

function GenerateConsentFormModal({ appt, handleClose }) {
  const [documentTypeId, setDocumentTypeId] = useState(
    appt.appointment_type === 'Surgery'
      ? Constants.SURGERY_CONSENT_FORM
      : Constants.AUTHORIZATION_FORM
  );

  const navigate = useNavigate();

  const handleSubmit = async () => {
    await http.post('/forms/generateConsentForm', {
      appointmentId: appt.id,
      documentTypeId: documentTypeId,
    });
    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <InputSelect
          name="documentTypeId"
          value={documentTypeId}
          label="Document Type"
          optionConfig={[
            Constants.DOCUMENT_TYPE_MAP[1],
            Constants.DOCUMENT_TYPE_MAP[2],
            Constants.DOCUMENT_TYPE_MAP[3],
          ]}
          onChange={setDocumentTypeId}
        />
        <ButtonPanel
          primaryButtonText="Generate"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          disabled={!documentTypeId}
        />
      </form>
    </Modal>
  );
}

function GenerateApptSummaryModal({ appointmentId, handleClose }) {
  const [summary, setSummary] = useState();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getSummary = async () => {
      setIsLoading(true);
      const response = await http.post(
        '/appointment/generateSummary',
        { appointmentId },
        { responseType: 'blob' }
      );

      const file = new Blob([response.data], { type: 'application/pdf' });
      const summary = URL.createObjectURL(file);
      setSummary(summary);
      setIsLoading(false);
    };
    getSummary();
  }, [appointmentId]);

  return (
    <Modal className="modal-auto-width" show={true} onHide={handleClose} centered>
      <form>
        {isLoading && (
          <i className="flex-centered p-5 w-100 fa fa-circle-notch fa-spin fa-2x subtle" />
        )}
        <embed
          src={summary}
          className="mt-4"
          type="application/pdf"
          width="1000px"
          height="1150px"
        />
      </form>
    </Modal>
  );
}

function GenerateTreatmentSheetModal({ petId, appointmentId, handleClose }) {
  const [treatmentSheet, setTreatmentSheet] = useState();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getTreatmentSheet = async () => {
      setIsLoading(true);
      const response = await http.post(
        '/appointment/generateTreatmentSheet',
        { petId, appointmentId },
        { responseType: 'blob' }
      );

      const file = new Blob([response.data], { type: 'application/pdf' });
      const treatmentSheet = URL.createObjectURL(file);
      setTreatmentSheet(treatmentSheet);
      setIsLoading(false);
    };
    getTreatmentSheet();
  }, [petId, appointmentId]);

  return (
    <Modal className="modal-auto-width" show={true} onHide={handleClose} centered>
      <form>
        {isLoading && (
          <i className="flex-centered p-5 w-100 fa fa-circle-notch fa-spin fa-2x subtle" />
        )}
        <embed
          src={treatmentSheet}
          className="mt-4"
          type="application/pdf"
          width="1000px"
          height="1150px"
        />
      </form>
    </Modal>
  );
}

function GenerateIntakeDocsModal({ appointmentId, handleClose }) {
  const [intakeDocs, setIntakeDocs] = useState();

  useEffect(() => {
    const getIntakeDocs = async () => {
      const response = await http.post(
        '/forms/generateIntakeDocs',
        { appointmentId },
        { responseType: 'blob' }
      );

      const file = new Blob([response.data], { type: 'application/pdf' });
      const pdf = URL.createObjectURL(file);
      setIntakeDocs(pdf);
    };
    getIntakeDocs();
  }, [appointmentId]);

  return (
    <Modal className="modal-auto-width" show={true} onHide={handleClose} centered>
      <form>
        <embed
          src={intakeDocs}
          className="mt-4"
          type="application/pdf"
          width="1000px"
          height="1150px"
        />
      </form>
    </Modal>
  );
}
