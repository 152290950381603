import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import { getRank } from '../../services/surgeryService';

import InputCheckbox from '../common/inputCheckbox';
import InputSelect from '../common/inputSelect';

import SurgeryScheduleModal from './surgeryScheduleModal';

function SurgeryFillerModal({ block, surgeries, scheduleColumns, doctors, handleClose }) {
  const [doctorId, setDoctorId] = useState(block.doctor_id);
  const [includeEarlierDates, setIncludeEarlierDates] = useState(false);
  const [results, setResult] = useState([]);

  const [selectedSurgery, setSelectedSurgery] = useState(null);
  const [showSurgeryScheduleModal, setShowSurgeryScheduleModal] = useState(false);

  useEffect(() => {
    const findSurgeries = async () => {
      let results = [];

      for (const surgery of surgeries) {
        const surgeryDate = moment(surgery.start).tz('America/Denver').format('YYYY-MM-DD');
        const blockDate = moment(block.start).tz('America/Denver').format('YYYY-MM-DD');

        if (blockDate === surgeryDate) continue;
        //if (surgery.isCancellationList !== 'Y') continue;
        if (moment().tz('America/Denver').format('YYYY-MM-DD') === surgeryDate) continue;
        if (!includeEarlierDates && surgeryDate < blockDate) continue;
        if (doctorId && Number(surgery.doctor_id) !== Number(doctorId)) continue;

        const rank = getRank(
          block,
          block.doctor_id,
          surgery.surgeryTypeId,
          surgery.numBlocks,
          surgery.isUrgent
        );
        results.push({ ...surgery, rank });
      }

      results = results
        .filter(t => t.rank > 0 /*&& t.rank < 10*/)
        //sort by rank, then date (reverse)
        .sort((a, b) => a.rank - b.rank || moment(b.start).isBefore(moment(a.start)));

      setResult(results);
    };
    findSurgeries();
  }, [block, surgeries, doctorId, includeEarlierDates]);

  if (showSurgeryScheduleModal) {
    return (
      <SurgeryScheduleModal
        block={block}
        surgery={selectedSurgery}
        scheduleColumns={scheduleColumns}
        handleClose={() => setShowSurgeryScheduleModal(false)}
      />
    );
  }

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <p className="font-16 sura mb-3">
          Looking for a surgery to schedule for Dr. {block.doctor} on{' '}
          {moment.tz(block.start, 'America/Denver').format('MM/DD/YYYY')}
        </p>
        <InputSelect
          name="doctorId"
          value={doctorId}
          label="Current Surgery Doctor"
          optionConfig={[{ name: 'No Preference', value: '' }, ...doctors]}
          onChange={setDoctorId}
        />
        <InputCheckbox
          className="mb-4"
          name="includeEarlierDates"
          label={`Include surgeries that are currently scheduled before ${moment
            .tz(block.start, 'America/Denver')
            .format('MM/DD/YYYY')}`}
          checked={includeEarlierDates}
          onChange={setIncludeEarlierDates}
        />
        {results.length < 1 && <p className="sura font-16 mb-4">No results found</p>}
        {results.map((t, i) => (
          <p
            key={i}
            className="pointer hover-underline"
            onClick={() => {
              setSelectedSurgery(t);
              setShowSurgeryScheduleModal(true);
            }}
          >
            {moment(t.start).format('MM/DD/YYYY')}: {t.name} ({t.first_name} {t.last_name}) -{' '}
            {t.complaint}
          </p>
        ))}
        <button
          type="button"
          className="mt-4 align-self-end btn-filled-primary"
          onClick={() => handleClose()}
        >
          Close
        </button>
      </form>
    </Modal>
  );
}

export default SurgeryFillerModal;
