import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import AdminPetOptions from './adminPetOptions';
import PetAppointments from './petAppointments';
import PetHealthPlans from './petHealthPlans';
import PetMedicalNotes from './petMedicalNotes';
import PetReminders from './petReminders';

import Icon from '../common/icon';

import Constants from '../../constants';

import '../../styles/components/pet.scss';

function Pet({ petId, client }) {
  const [pet, setPet] = useState({});
  const [selectedSection, setSelectedSection] = useState('HEALTH_PLANS');

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPet = async () => {
      try {
        setIsLoading(true);
        const response = await http.get(`/pet/getById?pet_id=${petId}`);
        setPet(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };

    getPet();
  }, [petId]);

  return (
    <div className="content-panel">
      {isLoading && <i className="flex-centered h-100 fa fa-circle-notch fa-spin fa-2x subtle" />}
      {!isLoading && errorMessage && <p className="error flex-centered mt-5">{errorMessage}</p>}
      {!isLoading && !errorMessage && (
        <React.Fragment>
          <div className="pet-tabs">
            <ul className="d-flex">
              <li
                className={`${selectedSection === 'HEALTH_PLANS' ? 'selected' : ''}`}
                onClick={() => setSelectedSection('HEALTH_PLANS')}
              >
                Health Plans
              </li>
              <li
                className={`${selectedSection === 'APPOINTMENTS' ? 'selected' : ''}`}
                onClick={() => setSelectedSection('APPOINTMENTS')}
              >
                Appointments
              </li>
              <li
                className={`${selectedSection === 'MEDICAL_NOTES' ? 'selected' : ''}`}
                onClick={() => setSelectedSection('MEDICAL_NOTES')}
              >
                Medical Notes
              </li>
            </ul>
          </div>
          <div className="pet-info p-3">
            <div className="flex-row-aligned justify-content-between mb-3">
              <div className="flex-row-aligned">
                {Number(pet.species_id) === Constants.CANINE && <Icon name="dog" />}
                {Number(pet.species_id) === Constants.FELINE && <Icon name="cat" />}
                <h2 className="me-2">
                  <span>{pet.name}</span>
                  <span className="ms-2 blue font-16">({pet.daysmart_pet_id})</span>
                </h2>
              </div>
              <AdminPetOptions pet={pet} />
            </div>
            <div className="d-flex">
              <div>
                <div className="attribute">
                  <label>Status:</label>
                  {pet.is_active === 'Y' ? (
                    <p>Active</p>
                  ) : pet.deceased_date ? (
                    <p className="error">
                      Deceased ({moment(pet.deceased_date).format('MMM DD, YYYY')})
                    </p>
                  ) : pet.inactive_date ? (
                    <p className="orange">
                      Inactive ({moment(pet.inactive_date).format('MMM DD, YYYY')})
                    </p>
                  ) : (
                    <p className="orange">Inactive</p>
                  )}
                </div>
                <div className="attribute">
                  <label>Species:</label>
                  <p>{pet.species}</p>
                </div>
                <div className="attribute">
                  <label>Breed:</label>
                  <div>
                    <p>{pet.primary_breed}</p>
                    {pet.secondary_breed && <p className="mt-1 mb-3">{pet.secondary_breed}</p>}
                  </div>
                </div>
                <div className="attribute">
                  <label>Color:</label>
                  <p>{pet.color}</p>
                </div>
                <div className="attribute">
                  <label>Sex:</label>
                  <p>{pet.sex}</p>
                </div>
                <div className="attribute">
                  <label>Age:</label>
                  <p>{pet.age}</p>
                </div>
                <div className="attribute">
                  <label>Birthdate:</label>
                  <p>{pet.birthdate ? moment.utc(pet.birthdate).format('MMM DD, YYYY') : ''}</p>
                </div>
                <div className="attribute">
                  <label>Microchip:</label>
                  <p>{pet.microchip}</p>
                </div>
                <div className="attribute">
                  <label>Weight:</label>
                  <p>{pet.weight}</p>
                </div>
                <div className="mt-3 attribute">
                  <label>Next Visit:</label>
                  <p>
                    {pet.nextVisit
                      ? `${moment
                          .tz(pet.nextVisit, 'America/Denver')
                          .format('MMM DD, YYYY @ hh:mm a')}`
                      : ''}
                  </p>
                </div>
              </div>
              <div style={{ marginLeft: '175px' }}>
                <PetReminders petId={pet.id} />
              </div>
            </div>
          </div>
          {selectedSection === 'HEALTH_PLANS' && (
            <PetHealthPlans pet={pet} client={client} hideNoContract={pet.deceased === 'Y'} />
          )}
          {selectedSection === 'APPOINTMENTS' && <PetAppointments pet={pet} />}
          {selectedSection === 'MEDICAL_NOTES' && <PetMedicalNotes pet={pet} />}
        </React.Fragment>
      )}
    </div>
  );
}

export default Pet;
