import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import AdminAppointmentOptions from './adminAppointmentOptions';
import AppointmentCancellationModal from './appointmentCancellationModal';
import BlockOffModal from './blockOffModal';
import CheckOutModal from './checkOutModal';

import ButtonPanel from '../common/buttonPanel';
import Icon from '../common/icon';
import Input from '../common/input';
import InputCheckbox from '../common/inputCheckbox';
import InputCustom from '../common/inputCustom';
import InputSelect from '../common/inputSelect';
import InputTime from '../common/inputTime';
import InputTextarea from '../common/inputTextarea';

import AppointmentConstants from '../../appointmentConstants';

function AppointmentModal({ appt, handleClose }) {
  const locationId = localStorage.getItem('locationId');
  const isLoveland = Number(locationId) === 1;
  const isUpdate = Boolean(appt.id);
  const appointmentStatusId = Number(appt.appointment_status_id);

  const [scheduleColumnId, setScheduleColumnId] = useState(appt.resourceId);
  const [petId, setPetId] = useState(isUpdate ? appt.pet_id : '');
  const [clientId, setClientId] = useState(isUpdate ? appt.client_id : '');
  const [clientName, setClientName] = useState(
    isUpdate ? `${appt.first_name} ${appt.last_name}` : ''
  );
  const [appointmentTypeId, setAppointmentTypeId] = useState(
    isUpdate ? appt.appointment_type_id : ''
  );
  const [complaint, setComplaint] = useState(isUpdate ? appt.complaint : '');
  const [startDate, setStartDate] = useState(moment(appt.start).format('YYYY-MM-DD'));
  const [startTime, setStartTime] = useState(moment(appt.start).format('HH:mm'));
  const [endDate, setEndDate] = useState(moment(appt.end).format('YYYY-MM-DD'));
  const [endTime, setEndTime] = useState(moment(appt.end).format('HH:mm'));
  const [notes, setNotes] = useState(isUpdate ? appt.notes || '' : '');
  const [sendConfirmationEmail, setSendConfirmationEmail] = useState(true);

  const [scheduleColumns, setScheduleColumns] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);

  const [showBlockOffModal, setShowBlockOffModal] = useState(false);
  const [showCheckOutModal, setShowCheckOutModal] = useState(false);
  const [showCancellationModal, setShowCancellationModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const getScheduleColumns = async () => {
        const response = await http.get(`/schedule/getColumns?location_id=${locationId}`);
        const scheduleColumns = response.data.map(t => ({ value: t.id, name: t.name }));
        setScheduleColumns(scheduleColumns);
      };
      getScheduleColumns();

      const getAppointmentTypes = async () => {
        const response = await http.get(`/appointment/getAppointmentTypes`);
        const appointmentTypes = response.data.map(t => ({ value: t.id, name: t.name }));
        setAppointmentTypes([{ value: '', name: 'Select Type' }, ...appointmentTypes]);
      };
      getAppointmentTypes();
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  }, [locationId]);

  const handleSubmit = async () => {
    const params = {
      scheduleColumnId,
      petId,
      appointmentTypeId,
      complaint,
      start: moment.tz(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm A', 'America/Denver'),
      end: moment.tz(`${endDate} ${endTime}`, 'YYYY-MM-DD HH:mm A', 'America/Denver'),
      notes,
      sendConfirmationEmail: sendConfirmationEmail ? 'Y' : 'N',
    };

    try {
      if (isUpdate) {
        await http.post(`/appointment/updateAppointment`, { id: appt.id, ...params });
      } else {
        await http.post(`/appointment/scheduleAppointment`, params);
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }

    navigate(0);
  };

  const handleAppointmentTypeChange = appointmentTypeId => {
    setAppointmentTypeId(appointmentTypeId);
    const apptType = appointmentTypes.find(t => t.value === appointmentTypeId);
    setEndTime(
      moment
        .tz(startTime, 'hh:mm A', 'America/Denver')
        .add('minutes', apptType.default_duration)
        .format('hh:mm A')
    );
  };

  const handleAppointmentConfirmation = async e => {
    e.preventDefault();
    await http.post('/appointment/confirmAppointment', { appointmentId: appt.id });
    navigate(0);
  };

  const handleAppointmentCheckIn = async e => {
    e.preventDefault();
    await http.post('/appointment/checkIn', { appointmentId: appt.id });
    navigate(0);
  };

  const handleAppointmentAdmit = async e => {
    e.preventDefault();
    await http.post('/appointment/admit', { appointmentId: appt.id });
    navigate(0);
  };

  const handleAppointmentUnconfirmation = async e => {
    e.preventDefault();
    await http.post('/appointment/unconfirmAppointment', { appointmentId: appt.id });
    navigate(0);
  };

  const handleAppointmentUndoCheckIn = async e => {
    e.preventDefault();
    await http.post('/appointment/undoCheckIn', { appointmentId: appt.id });
    navigate(0);
  };

  if (showBlockOffModal) {
    return (
      <BlockOffModal
        blockOff={{ start: appt.start, end: appt.end, resourceId: scheduleColumnId }}
        handleClose={handleClose}
      />
    );
  }

  if (showCancellationModal) {
    return (
      <AppointmentCancellationModal
        appointment={appt}
        handleClose={() => setShowCancellationModal(false)}
      />
    );
  }

  if (showCheckOutModal) {
    return (
      <CheckOutModal clientId={appt.client_id} handleClose={() => setShowCheckOutModal(false)} />
    );
  }

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <p className="error">{errorMessage}</p>
        {!isUpdate && Number(appointmentTypeId) === AppointmentConstants.SURGERY && (
          <p className="error input-width">
            Surgeries must be scheduled through the Surgery Dashboard
          </p>
        )}
        {isUpdate && (
          <div className="mb-2 align-self-end">
            <AdminAppointmentOptions appt={appt} />
          </div>
        )}
        <InputSelect
          name="scheduleColumnId"
          value={scheduleColumnId}
          label="Schedule Column"
          optionConfig={scheduleColumns}
          onChange={setScheduleColumnId}
          disabled={isLoveland}
        />
        <InputCustom
          selectedId={petId}
          retrievalEndpoint="/pet/getById?pet_id="
          searchEndpoint="/pet/search?"
          displayNameField="displayName"
          label="Pet"
          handleChange={pet => {
            setPetId(pet.id);
            setClientId(pet.client_id);
            setClientName(`${pet.first_name} ${pet.last_name}`);
          }}
          handleClear={() => setPetId(null)}
          disableClear={isUpdate}
          disabled={isLoveland}
          link={`/client/${clientId}/pet/${petId}`}
        />
        {petId && (
          <Link to={`/client/${clientId}`}>
            <Input name="clientName" value={clientName} label="Client" clickable={true} />
          </Link>
        )}
        <div className="d-flex input-width">
          <InputSelect
            name="appointmentTypeId"
            value={appointmentTypeId}
            label="Appointment Type"
            optionConfig={appointmentTypes}
            onChange={handleAppointmentTypeChange}
            disabled={isLoveland}
          />
          {!isLoveland && (
            <button onClick={() => setShowBlockOffModal(true)}>
              <Icon name="fa fa-sync-alt" className="mt-3 ms-3" tooltipText="Switch to Block Off" />
            </button>
          )}
        </div>
        <InputTextarea
          name="complaint"
          value={complaint}
          label="Complaint"
          rows="2"
          onChange={setComplaint}
          disabled={isLoveland}
        />
        <div className="input-width d-flex">
          <Input
            name="startDate"
            type="date"
            value={startDate}
            label="Start"
            onChange={value => {
              setStartDate(value);
              setEndDate(value);
            }}
            disabled={isLoveland}
          />
          <InputTime
            className="ms-2"
            value={startTime}
            onChange={setStartTime}
            label="Time"
            disabled={isLoveland}
          />
        </div>
        <div className="input-width d-flex">
          <Input
            name="endDate"
            type="date"
            value={endDate}
            label="End"
            onChange={setEndDate}
            disabled={isLoveland}
          />
          <InputTime
            className="ms-2"
            value={endTime}
            onChange={setEndTime}
            label="Time"
            disabled={isLoveland}
          />
        </div>
        <InputTextarea
          name="notes"
          value={notes}
          label="Notes"
          rows="10"
          onChange={setNotes}
          disabled={isLoveland}
        />
        {(!isUpdate ||
          !moment
            .tz(appt.start, 'America/Denver')
            .isSame(moment.tz(`${startDate}T${startTime}`, 'America/Denver'))) && (
          <InputCheckbox
            className="mt-2 mb-3"
            name="sendConfirmationEmail"
            label="Send Confirmation Email"
            checked={sendConfirmationEmail}
            onChange={setSendConfirmationEmail}
          />
        )}
        {!isLoveland && isUpdate && (
          <div className="d-flex">
            {appointmentStatusId === AppointmentConstants.NOT_CONFIRMED && (
              <button onClick={e => handleAppointmentConfirmation(e)}>
                <Icon
                  name="far fa-calendar-check"
                  className="ms-3"
                  tooltipText="Confirm Appointment"
                />
              </button>
            )}

            {(appointmentStatusId === AppointmentConstants.NOT_CONFIRMED ||
              appointmentStatusId === AppointmentConstants.CONFIRMED) && (
              <button onClick={e => handleAppointmentCheckIn(e)}>
                <Icon name="fa fa-check-circle" className="ms-3" tooltipText="Check In" />
              </button>
            )}

            {(appointmentStatusId === AppointmentConstants.NOT_CONFIRMED ||
              appointmentStatusId === AppointmentConstants.CONFIRMED) && (
              <button onClick={e => handleAppointmentAdmit(e)}>
                <Icon name="fa fa-notes-medical" className="ms-3" tooltipText="Admit" />
              </button>
            )}

            {appointmentStatusId === AppointmentConstants.CONFIRMED && (
              <button onClick={e => handleAppointmentUnconfirmation(e)}>
                <Icon
                  name="far fa-calendar-times"
                  className="ms-3"
                  tooltipText="Unconfirm Appointment"
                />
              </button>
            )}

            {(appointmentStatusId === AppointmentConstants.CHECKED_IN ||
              appointmentStatusId === AppointmentConstants.ADMITTED) && (
              <button onClick={e => handleAppointmentUndoCheckIn(e)}>
                <Icon name="fa fa-rotate-left" className="ms-3" tooltipText="Undo Check In" />
              </button>
            )}

            {(appointmentStatusId === AppointmentConstants.CHECKED_IN ||
              appointmentStatusId === AppointmentConstants.ADMITTED) && (
              <button onClick={() => setShowCheckOutModal(true)}>
                <Icon name="fa fa-door-closed" className="ms-3" tooltipText="Check Out" />
              </button>
            )}

            {appointmentStatusId !== AppointmentConstants.CHECKED_OUT && (
              <button onClick={() => setShowCancellationModal(true)}>
                <Icon name="fas fa-ban" className="ms-3" tooltipText="Cancel Appointment" />
              </button>
            )}
          </div>
        )}

        {!isLoveland && (
          <ButtonPanel
            primaryButtonText="Save"
            handleCancel={handleClose}
            handleSubmit={handleSubmit}
            disabled={
              (!isUpdate && Number(appointmentTypeId) === AppointmentConstants.SURGERY) ||
              !appointmentTypeId ||
              !complaint ||
              !petId ||
              !startDate ||
              !startTime ||
              !endDate ||
              !endTime
            }
          />
        )}
      </form>
    </Modal>
  );
}

export default AppointmentModal;
