import React, { useState, useEffect } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import Icon from '../common/icon';

function PetMedicalNotes({ pet }) {
  const [medicalNotes, setMedicalNotes] = useState([]);
  const [isExpanded, setIsExpanded] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getMedicalNotes = async () => {
      try {
        setIsLoading(true);
        const response = await http.get(`/pet/getMedicalNotes?pet_id=${pet.id}`);
        setMedicalNotes(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setIsLoading(false);
    };

    if (pet.id) {
      getMedicalNotes();
    }
  }, [pet]);

  const getSegment = (node, index) => {
    return (
      <div key={index} className="ms-4 mb-2">
        {node.label === 'Notes' && (
          <React.Fragment>
            <p>{node.value}</p>
            <p>{node.comment}</p>
          </React.Fragment>
        )}
        {node.label !== 'Notes' && (
          <React.Fragment>
            <p className="roboto mb-2">{node.label}</p>
            <p className="ms-4">{node.value}</p>
            <p className="ms-4">{node.comment}</p>
          </React.Fragment>
        )}
        {node.data && node.data.map((t, i) => getSegment(t, i))}
      </div>
    );
  };

  return (
    <div className="background-white p-3 mt-3">
      {errorMessage && <p className="error flex-centered h-100 background-white">{errorMessage}</p>}
      {isLoading && (
        <i className="flex-centered h-100 fa fa-circle-notch fa-spin fa-2x subtle mt-5" />
      )}
      {!isLoading && (
        <React.Fragment>
          <h2 className="my-4 ms-2">Medical Notes</h2>
          <button
            className="mb-3 me-5 btn-text-primary "
            onClick={() => {
              const indexes = [];
              medicalNotes.map((t, i) => (indexes[i] = !isExpanded[0]));
              setIsExpanded(indexes);
            }}
          >
            {!isExpanded[0] && <Icon name="add" />}
            {isExpanded[0] && <Icon name="subtract" />}
            {!isExpanded[0] ? 'Expand' : 'Collapse'} All
          </button>
          {medicalNotes.map((t, i) => {
            return (
              <div key={i} className="px-4 pt-4 my-2 border">
                <h3
                  className="d-flex mb-3"
                  onClick={() => {
                    const updatedIsExpanded = { ...isExpanded };
                    updatedIsExpanded[i] = !isExpanded[i];
                    setIsExpanded(updatedIsExpanded);
                  }}
                >
                  {isExpanded[i] && <Icon name="less" />}
                  {!isExpanded[i] && <Icon name="more" />}
                  <p className="roboto font-14">
                    {moment.tz(t.date, 'America/Denver').format('MMM DD, YYYY')}
                  </p>
                  <p className="roboto font-14 blue ms-5">{t.complaint}</p>
                </h3>
                <Collapse in={isExpanded[i]}>
                  <div className="ps-4 py-2">{t.content.map((s, j) => getSegment(s, j))}</div>
                </Collapse>
              </div>
            );
          })}
        </React.Fragment>
      )}
    </div>
  );
}

export default PetMedicalNotes;
