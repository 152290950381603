import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import GenerateInvoiceModal from '../admin/generateInvoiceModal';

import ButtonPanel from '../common/buttonPanel';
import Icon from '../common/icon';
import Input from '../common/input';
import InputCheckbox from '../common/inputCheckbox';
import OneTimePayment from '../client/oneTimePayment';

import { formatCurrency } from '../../util';

function CheckOutModal({ clientId, handleClose }) {
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointments, setSelectedAppointments] = useState([]);

  const [invoices, setInvoices] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const [balance, setBalance] = useState(0);
  const [emailInvoice, setEmailInvoice] = useState(true);
  const [email, setEmail] = useState('');

  const [showMakePayment, setShowMakePayment] = useState(false);
  const [showGenerateInvoiceModal, setShowGenerateInvoiceModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getOpenAppointments = async () => {
      const response = await http.get(`/client/getOpenAppointments?client_id=${clientId}`);

      const appointments = response.data;
      setAppointments(appointments);
      setSelectedAppointments(appointments.map(t => t.id));
    };
    getOpenAppointments();

    const getInvoices = async () => {
      const response = await http.get(`/client/getOpenInvoices?client_id=${clientId}`);

      const invoices = response.data;
      setInvoices(invoices);
      setSelectedInvoices(invoices.map(t => t.id));
    };
    getInvoices();

    const getClientInfo = async () => {
      const response = await http.get(`/client/getCompleteById?client_id=${clientId}`);
      const client = response.data;

      setBalance(client.balance);
      setEmail(client.email);
      setEmailInvoice(Boolean(client.email));
    };
    getClientInfo();
  }, [clientId]);

  const handleSubmit = async () => {
    const params = {
      appointmentIds: selectedAppointments,
      invoiceEmail: emailInvoice && email && selectedInvoices.length > 0 ? email : null,
    };

    if (params.invoiceEmail) {
      params.invoiceIds = selectedInvoices;
    }

    await http.post('/client/checkOut', params);

    if (balance > 0) {
      setShowMakePayment(true);
    } else {
      navigate(0);
    }
  };

  if (showMakePayment) {
    return (
      <OneTimePayment balance={balance} clientId={clientId} handleClose={() => handleClose()} />
    );
  }

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <div className="input-width">
          {appointments.length > 0 && (
            <div className="mb-3">
              <h3 className="sura text-decoration-underline">Appointments</h3>
              {appointments.map((t, i) => (
                <div key={i} className="d-flex justify-content-start align-items-center">
                  <InputCheckbox
                    className="my-2 justify-self-start"
                    name={`appt_${t.id}`}
                    label={`${t.name} - ${moment
                      .tz(t.start, 'America/Denver')
                      .format('MM/DD/YYYY @ hh:mm A')}`}
                    checked={Boolean(selectedAppointments.find(a => a === t.id))}
                    onChange={value => {
                      const updatedSelectedAppointments = [...selectedAppointments];

                      if (value) {
                        updatedSelectedAppointments.push(t.id);
                      } else {
                        const index = updatedSelectedAppointments.findIndex(a => a === t.id);
                        updatedSelectedAppointments.splice(index, 1);
                      }

                      setSelectedAppointments(updatedSelectedAppointments);
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        {invoices.length > 0 && (
          <React.Fragment>
            <div className="mb-3">
              <h3 className="sura text-decoration-underline">Invoices</h3>
              {invoices.map((t, i) => (
                <div key={i} className="d-flex justify-content-start align-items-center">
                  <InputCheckbox
                    className="my-2 justify-self-start"
                    name={`appt_${t.id}`}
                    label={`Invoice #${t.index}`}
                    checked={Boolean(selectedInvoices.find(s => s === t.id))}
                    onChange={value => {
                      const updatedSelectedInvoices = [...selectedInvoices];

                      if (value) {
                        updatedSelectedInvoices.push(t.id);
                      } else {
                        const index = updatedSelectedInvoices.findIndex(s => s === t.id);
                        updatedSelectedInvoices.splice(index, 1);
                      }

                      setSelectedInvoices(updatedSelectedInvoices);
                    }}
                    disabled={!emailInvoice}
                  />
                  <p className="ms-3 sura">{formatCurrency(t.total)}</p>
                  <button
                    onClick={e => {
                      e.preventDefault();
                      setSelectedInvoice(t);
                      setShowGenerateInvoiceModal(true);
                    }}
                  >
                    <Icon name="fa fa-search" className="ms-3" tooltipText="Preview Invoice" />
                  </button>
                </div>
              ))}
            </div>

            <InputCheckbox
              className="mb-2"
              name="emailInvoice"
              label="Email Selected Invoice(s)"
              checked={emailInvoice}
              onChange={value => {
                setEmailInvoice(value);
                if (!value) {
                  setSelectedInvoices([]);
                }
              }}
            />
            {emailInvoice && (
              <Input
                name="email"
                className="mb-3"
                label="Email"
                value={email}
                onChange={setEmail}
              />
            )}
          </React.Fragment>
        )}

        <div className="my-2">
          <h3 className="sura text-decoration-underline">Account Balance</h3>
          <p className="mt-1 sura">{formatCurrency(balance)}</p>
        </div>

        <ButtonPanel
          primaryButtonText="Check Out"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          disabled={selectedAppointments.length === 0}
        />
      </form>

      {showGenerateInvoiceModal && (
        <GenerateInvoiceModal
          invoiceId={selectedInvoice.id}
          handleClose={() => setShowGenerateInvoiceModal(false)}
        />
      )}
    </Modal>
  );
}

export default CheckOutModal;
