import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';

import CompleteRegistration from './components/auth/completeRegistration';
import ForgotPassword from './components/auth/forgotPassword';
import Login from './components/auth/login';
import ResetPassword from './components/auth/resetPassword';

import Client from './components/client/client';

import Admin from './components/admin/admin';
import AdminDashboard from './components/admin/dashboard/adminDashboard';
import AdminTools from './components/admin/adminTools';
import DoctorDashboard from './components/admin/doctorDashboard';
import EstimateGenerator from './components/admin/estimateGenerator';
import Estimates from './components/admin/estimates';
import Home from './components/admin/home';
import Inventory from './components/admin/inventory';
import InventoryDashboard from './components/admin/inventoryDashboard';
import Reports from './components/admin/reports/reports';
import ReviewDashboard from './components/admin/reviewDashboard';
import SurgeryDashboard from './components/admin/surgeryDashboard';
import Tasks from './components/admin/tasks';

import CancellationList from './components/schedule/cancellationList';
import Schedule from './components/schedule/schedule';

import Timesheets from './components/employee/timesheets';

import Forms from './components/forms';
import Account from './components/account';

import AuthorizationForm from './components/forms/authorizationForm';
import EuthanasiaConsentForm from './components/forms/euthanasiaConsentForm';
import SurgeryConsentForm from './components/forms/surgeryConsentForm';
import HealthPlanContract from './components/forms/healthPlanContract';
import PaymentPlanContract from './components/forms/paymentPlanContract';

import Constants from './constants';

import './styles/global/style.scss';

let loggedInUser = JSON.parse(localStorage.getItem('loggedIn'));

const ProtectedRoute = () => {
  loggedInUser = JSON.parse(localStorage.getItem('loggedIn'));
  return loggedInUser ? <Outlet /> : <Navigate to="/login" replace />;
};

const ManagerRoute = () => {
  loggedInUser = JSON.parse(localStorage.getItem('loggedIn'));
  const isManager = Number(loggedInUser.role_id) === Constants.MANAGER;
  return isManager ? <Outlet /> : <Navigate to="/" replace />;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/client/:clientId/pet/:petId" element={<Client />} />
          <Route path="/client/:clientId/health-plans" element={<Client />} />
          <Route path="/client/:clientId/payment-plans" element={<Client />} />
          <Route path="/client/:clientId/billing" element={<Client />} />
          <Route path="/client/:clientId/payment-methods" element={<Client />} />
          <Route path="/client/:clientId/documents" element={<Client />} />
          <Route exact path="/client/:clientId" element={<Client />} />
          <Route exact path="/tasks" element={<Tasks />} />
          <Route element={<ManagerRoute />}>
            <Route exact path="/admin" element={<Admin />} />
          </Route>
          <Route exact path="/admin-dashboard" element={<AdminDashboard />} />
          <Route exact path="/admin-tools" element={<AdminTools />} />
          <Route exact path="/doctor-dashboard" element={<DoctorDashboard />} />
          <Route exact path="/estimates" element={<Estimates />} />
          <Route exact path="/estimate-generator" element={<EstimateGenerator />} />
          <Route exact path="/estimate-generator/:estimateId" element={<EstimateGenerator />} />
          <Route
            exact
            path="/estimate-generator/duplicate/:estimateId"
            element={<EstimateGenerator isDuplicate={true} />}
          />
          <Route exact path="/cancellation-list" element={<CancellationList />} />
          <Route exact path="/inventory" element={<InventoryDashboard />} />
          <Route exact path="/inventory/:inventoryId" element={<Inventory />} />
          <Route exact path="/review-dashboard" element={<ReviewDashboard />} />
          <Route exact path="/surgery-dashboard" element={<SurgeryDashboard />} />
          <Route exact path="/schedule" element={<Schedule />} />
          <Route exact path="/timesheets/:employeeId" element={<Timesheets />} />
          <Route element={<ManagerRoute />}>
            <Route exact path="/timesheets" element={<Timesheets />} />
          </Route>
          <Route exact path="/reports" element={<Reports />} />
          {loggedInUser && (
            <Route
              exact
              path="/account"
              element={<Account employeeId={loggedInUser.id} isPage={true} />}
            />
          )}
        </Route>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:userId/:resetToken" element={<ResetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route exact path="/forms" element={<Forms />} />
        <Route exact path="/authorization-form/:formId" element={<AuthorizationForm />} />
        <Route exact path="/euthanasia-consent-form/:formId" element={<EuthanasiaConsentForm />} />
        <Route exact path="/surgery-consent-form/:formId" element={<SurgeryConsentForm />} />
        <Route exact path="/health-plan-contract/:formId" element={<HealthPlanContract />} />
        <Route exact path="/payment-plan-contract/:formId" element={<PaymentPlanContract />} />
        <Route path="/register/:userId/:registrationToken" element={<CompleteRegistration />} />

        <Route element={<ProtectedRoute />}>
          <Route exact path="/" element={<Home />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
