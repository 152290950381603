import React, { useState } from 'react';
import moment from 'moment-timezone';

import http from '../../../services/httpService';

import Input from '../../common/input';

function DoctorSchedule() {
  const locationId = localStorage.getItem('locationId');

  const [results, setResults] = useState([]);

  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      const response = await http.post(`/report/getDoctorScheduleReport`, {
        startDate,
        endDate,
        locationId,
      });
      setResults(response.data);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <div className="d-flex">
        <Input
          className="me-2"
          type="date"
          name="startDate"
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
        <Input type="date" name="endDate" label="End Date" value={endDate} onChange={setEndDate} />
      </div>
      <button
        className="my-3 me-5 btn-filled-primary input-width"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        Run
      </button>
      {errorMessage && <p className="error">{errorMessage}</p>}
      {isLoading && (
        <i className="fa fa-circle-notch fa-spin fa-2x flex-centered flex-grow-1 p-5 subtle" />
      )}
      {!isLoading && (
        <div>
          <h2 className="mb-3">Count: {results.length}</h2>
          {results.length > 0 && (
            <div className="p-2">
              <table>
                <thead>
                  <tr className="border-bottom">
                    <th>Date</th>
                    <th>Doctor</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Lunch (min.)</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                  {results.map((t, i) => (
                    <tr key={i}>
                      <td>{moment.tz(t.start, 'America/Denver').format('MM-DD-YYYY')}</td>
                      <td>{t.last_name}</td>
                      <td>{moment.tz(t.start, 'America/Denver').format('hh:mm A')}</td>
                      <td>{moment.tz(t.end, 'America/Denver').format('hh:mm A')}</td>
                      <td>{t.lunch_allotment}</td>
                      <td>
                        {moment(t.end).diff(t.start, 'hours', true) -
                          Number(t.lunch_allotment) / 60}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export default DoctorSchedule;
