import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import { getRank } from '../../services/surgeryService';

import SurgeryScheduleModal from './surgeryScheduleModal';

import Input from '../common/input';
import InputEitherOr from '../common/inputEitherOr';
import InputSelect from '../common/inputSelect';

import EstimateConstants from '../../estimateConstants';

function SurgeryFinderModal({ surgery, scheduledBlocks, scheduleColumns, doctors, handleClose }) {
  const [doctorId, setDoctorId] = useState(surgery ? surgery.doctor_id : '');
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [blocks, setBlocks] = useState(surgery ? surgery.numBlocks : 1);
  const [surgeryType, setSurgeryType] = useState(surgery ? surgery.surgeryTypeId : 0);
  const [isUrgent, setIsUrgent] = useState(surgery ? surgery.isUrgent : 'N');

  const [results, setResult] = useState([]);

  const [selectedBlock, setSelectedBlock] = useState(null);
  const [selectedSurgery, setSelectedSurgery] = useState(null);
  const [showSurgeryScheduleModal, setShowSurgeryScheduleModal] = useState(false);

  useEffect(() => {
    const findSurgeryDates = async () => {
      let results = [];

      for (const block of scheduledBlocks) {
        const rank = getRank(
          block,
          doctorId,
          surgeryType,
          blocks,
          isUrgent,
          minDate,
          maxDate,
          true
        );
        results.push({ ...block, rank });
      }

      results = results
        .filter(t => t.rank > 0)
        .sort((a, b) => a.rank - b.rank || a.start - b.start);

      setResult(results);
    };
    findSurgeryDates();
  }, [doctorId, surgeryType, blocks, isUrgent, minDate, maxDate, scheduledBlocks]);

  if (showSurgeryScheduleModal) {
    return (
      <SurgeryScheduleModal
        block={selectedBlock}
        surgery={selectedSurgery}
        scheduleColumns={scheduleColumns}
        handleClose={() => setShowSurgeryScheduleModal(false)}
      />
    );
  }

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <InputSelect
          name="doctorIdFinder"
          value={doctorId}
          label="Doctor"
          optionConfig={[{ name: 'No Preference', value: '' }, ...doctors]}
          onChange={setDoctorId}
        />
        <InputSelect
          name="surgeryTypeFinder"
          label="Surgery Type"
          value={surgeryType}
          optionConfig={EstimateConstants.SURGERY_TYPE_MAP}
          onChange={value => {
            const surgeryType = Number(value);
            //by default, only look out 4 weeks for anything except spays and neuters
            if (![2, 3].includes(surgeryType)) {
              setMaxDate(moment().tz('America/Denver').add(4, 'weeks').format('YYYY-MM-DD'));
            }
            setSurgeryType(surgeryType);
          }}
        />
        <InputSelect
          name="blocksFinder"
          value={blocks}
          label="Total Blocks"
          optionConfig={[0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4]}
          rawOptions={true}
          onChange={value => setBlocks(Number(value))}
        />
        <InputEitherOr
          name="isUrgentFinder"
          label="Is Urgent?"
          value={isUrgent}
          option1={{ name: 'Yes', value: 'Y' }}
          option2={{ name: 'No', value: 'N' }}
          onChange={({ target }) => setIsUrgent(target.value)}
        />
        <Input name="minDate" type="date" value={minDate} label="Min Date" onChange={setMinDate} />
        <Input name="maxDate" type="date" value={maxDate} label="Max Date" onChange={setMaxDate} />
        <div className="mt-3">
          {surgeryType === 0 && (
            <p className="mb-1 sura input-width">
              Please select a Surgery Type to see available dates
            </p>
          )}
          {surgeryType > 0 && results.length === 0 && (
            <p className="mb-1 sura input-width">
              This surgery may require adjustment to schedule to accommodate. Please work with the
              med team to schedule appropriately.
            </p>
          )}
          {surgeryType > 0 && results.length > 0 && (
            <React.Fragment>
              <p className="mb-1 sura">These dates work best:</p>
              {results.map((t, i) => (
                <div
                  key={i}
                  className="pointer hover-underline my-1"
                  onClick={() => {
                    setSelectedSurgery({
                      ...surgery,
                      numBlocks: blocks,
                      surgeryTypeId: surgeryType,
                      isUrgent,
                    });
                    setSelectedBlock(t);
                    setShowSurgeryScheduleModal(true);
                  }}
                >
                  {moment.tz(t.start, 'America/Denver').format('MM/DD/YYYY (dddd)')} - {t.doctor}
                </div>
              ))}
            </React.Fragment>
          )}
        </div>
      </form>
    </Modal>
  );
}

export default SurgeryFinderModal;
