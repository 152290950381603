import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import Modal from 'react-bootstrap/Modal';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import InputEitherOr from '../common/inputEitherOr';
import InputNumber from '../common/inputNumber';
import InputSelect from '../common/inputSelect';

import { formatCurrency } from '../../util';
import Constants from '../../constants';

function SettlementAnalysis({ contract, handleClose }) {
  const [futureDiscount, setFutureDiscount] = useState(0);
  const [isDeceasedAnalysis, setIsDeceasedAnalysis] = useState(contract.isHealthPlan);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [settlementOption, setSettlementOption] = useState(null);
  const [cancellationReason, setCancellationReason] = useState(Constants.REHOMED);

  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
  });

  const petIsDeceased = contract.deceased === 'Y';
  const { isHealthPlan } = contract;
  const endPointName = contract.isHealthPlan ? 'healthPlan' : 'paymentPlan';

  const runSettlementAnalysis = useCallback(async () => {
    setErrorMessage('');
    const params = { contractId: contract.id };
    if (isHealthPlan) {
      params.futureDiscount = Math.round(futureDiscount * 100);
      params.isDeceasedAnalysis = isDeceasedAnalysis ? 'Y' : 'N';
    }
    const result = await http.post(`/${endPointName}/runSettlementAnalysis`, params);
    setAnalysisResults(result.data);
  }, [contract.id, isHealthPlan, futureDiscount, isDeceasedAnalysis, endPointName]);

  useEffect(() => {
    if (!isHealthPlan) {
      runSettlementAnalysis();
    }
  }, [isHealthPlan, runSettlementAnalysis]);

  const handleCancellation = async () => {
    await http.post(`/${endPointName}/cancel`, {
      contractId: contract.id,
      cancellationReason: isDeceasedAnalysis ? Constants.DECEASED_IMMEDIATE : cancellationReason,
    });
    navigate(0);
  };

  const handleSettlement = async () => {
    const params = { contractId: contract.id, settlementOption, analysisResults };

    if (isHealthPlan) {
      params.cancellationReason = isDeceasedAnalysis
        ? settlementOption === 'immediate'
          ? Constants.DECEASED_IMMEDIATE
          : Constants.DECEASED_PREVIOUS
        : cancellationReason;
    }

    await http.post(`/${endPointName}/settle`, params);
    navigate(0);
  };

  const analysisContent = (
    <React.Fragment>
      <InputNumber
        name="futureDiscount"
        value={futureDiscount}
        label="Addt'l discount for svcs not invoiced"
        onChange={setFutureDiscount}
      />
      {!petIsDeceased && (
        <InputEitherOr
          name="isDeceasedAnalysis"
          label="Is this a Deceased Analysis?"
          value={isDeceasedAnalysis}
          option1={{ name: 'Yes', value: true }}
          option2={{ name: 'No', value: false }}
          onChange={({ target }) => setIsDeceasedAnalysis(JSON.parse(target.value))}
        />
      )}
      {!isDeceasedAnalysis && (
        <InputSelect
          name="cancellationReason"
          value={cancellationReason}
          label="Reason for Cancelling"
          optionConfig={Constants.CANCELLATION_REASON_MAP.filter(t => t.value >= Constants.REHOMED)}
          onChange={setCancellationReason}
        />
      )}
      <ButtonPanel
        primaryButtonText="Run Analysis"
        handleCancel={handleClose}
        handleSubmit={runSettlementAnalysis}
        handleErrorMessage={setErrorMessage}
      />
    </React.Fragment>
  );

  const cancellationContent = (
    <React.Fragment>
      <p className="sura font-16">Health Plan is eligible for immediate cancellation</p>
      {isDeceasedAnalysis && !petIsDeceased && (
        <p className="sura mt-2">
          *Cancellation cannot be processed until pet is marked as deceased
        </p>
      )}
      <ButtonPanel
        primaryButtonText="Proceed with Cancellation"
        handleBack={() => {
          setAnalysisResults(null);
          setErrorMessage('');
        }}
        handleCancel={handleClose}
        handleSubmit={handleCancellation}
        handleErrorMessage={setErrorMessage}
        disabled={isDeceasedAnalysis && !petIsDeceased}
      />
    </React.Fragment>
  );

  const settlementContent = () => {
    return (
      <React.Fragment>
        <div ref={componentRef}>
          {isHealthPlan && (
            <div className="form-control-read-only mt-3">
              <label className="mb-2" htmlFor="totalDiscount">
                Total Discount from Health Plan:
              </label>
              <p id="totalDiscount">{formatCurrency(analysisResults.totalDiscount)}</p>
            </div>
          )}
          <div className="form-control-read-only mt-3">
            <label className="mb-2" htmlFor="totalPaymentsMade">
              Payments Made:
            </label>
            <p id="totalPaymentsMade">{formatCurrency(analysisResults.amountPaidIn)}</p>
          </div>
          <div className="form-control-read-only mt-3">
            <label className="mb-2" htmlFor="remainingBalance">
              Payments Remaining:
            </label>
            <p id="remainingBalance">{formatCurrency(analysisResults.remainingBalance)}</p>
          </div>
          <p className="sura bold mt-3 mb-1">Options to Settle Plan:</p>
          <div className="radio-line align-items-start mb-2">
            {(!isDeceasedAnalysis || petIsDeceased) && (
              <input
                className="mt-2"
                type="radio"
                id="immediate"
                name="settlementOption"
                checked={settlementOption === 'immediate'}
                value="immediate"
                onChange={({ target }) => setSettlementOption(target.value)}
              />
            )}
            {isDeceasedAnalysis && !petIsDeceased && <li />}
            <label className="fw-bolder news-cycle font-16" htmlFor="immediate">
              Pay Remaining Balance Now
              <p>{formatCurrency(analysisResults.settlementAmount)} total</p>
            </label>
          </div>
          <div className="radio-line align-items-start mb-3">
            {(!isDeceasedAnalysis || petIsDeceased) && (
              <input
                className="mt-2"
                type="radio"
                id="payments"
                name="settlementOption"
                checked={settlementOption === 'payments'}
                value="payments"
                onChange={({ target }) => setSettlementOption(target.value)}
              />
            )}
            {isDeceasedAnalysis && !petIsDeceased && <li />}
            <label className="fw-bolder news-cycle font-16" htmlFor="payments">
              Continue Monthly Payments
              <p>{analysisResults.remainingPayments} Remaining Payment(s)</p>
              <p>{formatCurrency(analysisResults.totalPaymentsAmount)} total</p>
            </label>
          </div>
        </div>
        <button
          className="btn-rounded-primary my-3"
          onClick={e => {
            e.preventDefault();
            handlePrint();
          }}
        >
          <i className="fas fa-print"></i>Print
        </button>
        {isDeceasedAnalysis && !petIsDeceased && (
          <p className="sura">*Settlement cannot be processed until pet is marked as deceased</p>
        )}
        {isHealthPlan && (
          <ButtonPanel
            primaryButtonText="Process Settlement"
            handleBack={() => {
              setAnalysisResults(null);
              setErrorMessage('');
            }}
            handleCancel={handleClose}
            handleSubmit={handleSettlement}
            handleErrorMessage={setErrorMessage}
            disabled={(isDeceasedAnalysis && !petIsDeceased) || !settlementOption}
          />
        )}
        {!isHealthPlan && (
          <ButtonPanel
            primaryButtonText="Process Settlement"
            handleCancel={handleClose}
            handleSubmit={handleSettlement}
            handleErrorMessage={setErrorMessage}
            disabled={!settlementOption}
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        {errorMessage && <p className="error input-width">{errorMessage}</p>}
        {!analysisResults && isHealthPlan && analysisContent}
        {!analysisResults && !isHealthPlan && (
          <i className="flex-centered w-100 p-4 fa fa-circle-notch fa-spin fa-2x subtle" />
        )}
        {analysisResults && analysisResults.isFulfilled && cancellationContent}
        {analysisResults && !analysisResults.isFulfilled && settlementContent()}
      </form>
    </Modal>
  );
}

export default SettlementAnalysis;
